import React from 'react';

export const IconTelegram = () => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        fill="#000"
        d="M24.6 2.5c-.3-.4-.8-.6-1.3-.6-.3 0-.6 0-.9.2L1.3 10C0 10.7 0 11.3 0 11.7c0 .3.2 1 1.3 1.3l4.5 1.3L8 21c.3.9 1 1.5 2 1.5.5 0 1-.3 1.4-.7l2.8-2.5 3.9 3.2c.5.4 1 .6 1.4.6 1 0 1.7-.7 1.9-1.8l3.4-17c.2-.8 0-1.4-.3-1.8zM7.2 13.9l8.5-4.3-5.3 5.6-.2.3-1 4.2-2-5.8zm3.3 6.8v.1l.9-3.8 1.7 1.4-2.6 2.3zM23.5 4L20 21c0 .2-.1.6-.4.6-.1 0-.3 0-.5-.2l-4.4-3.6-2.7-2.2 7.7-8a.7.7 0 00-1-1.2L6.3 12.8l-4.4-1.3 21.1-8 .4-.1h.2V4z"
      />
    </svg>
  );
};
